.main_Hdr {
    padding: 16px 0;
    background: #fff;
    width: 100%;
    position: sticky;
    border-radius: 0 0 18px 0;
    top: 0;
    z-index: 999;
    box-shadow: 4px 5px 10px rgb(0 0 0 / 5%);
}
.main_Hdr:after {
    position: absolute;
    left: -100px;
    background: #fff;
    content: "";
    width: 130px;
    height: calc(100% - 2px);
    top: 0;
    z-index: -1;
}
.hdr_Inr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.hdr_Title {
    font-size: 28px;
    font-family: var(--font-family2);
}
.logout_Btn_Menu{
    display: flex;
    flex-direction: column;
    padding: 30px 17px;
    max-width: 200px;
}
.logout_name{
    font-size: 14px;
    color: #000;
}
.email{
    font-size: 11px;
    color: #7A7A7A;
    margin: 2px 0;
    display: inline-block;
}
.lgt_btn {
    display: flex;
    align-items: center;
    padding: 10px 20px 0;
    border-top: 2px solid #d4d4d4;
    margin-top: 10px;
    gap: 9px;
    color: #000;
    cursor: pointer;
}