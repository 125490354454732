.cwt_wpr {
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 30px 26px;
}
.cwt_hdr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
}
.cwt_hdr_left h4 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
}
.cwt_hdr_left p {
    color: #9E9E9E;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.cwt_select > div:first-child > div {
    padding: 6px 30px 5px 10px !important;
    color: #686868;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    min-height: 10px !important;
    height: 18px!important;
    display: flex;
    align-items: center;
    background-color: #EEE;
    border-radius: 30px;
}
.cwt_select fieldset{
    border: 0 !important;
}

.cwt_accordion {
    padding-left: 40px;
    margin-top: 30px;
    position: relative;
}
.cwt_accordion:after {
    content: "";
    /* background: var(--secondry-color); */
    border-radius: 40px;
    position: absolute;
    width: 3px;
    height: calc(100% - 100px);
    min-height: 20px;
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
}
.cwt_accordion .start_data, .cwt_accordion .end_data {
    position: absolute;
    left: 0;
    color: #4B4B4B;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    width: 20px;
}
.cwt_accordion .end_data {
    /* bottom: 0; */
    bottom: 76px;

}
.dyn_acr {
    box-shadow: none !important;
    margin-bottom: 16px !important;
}
.dyn_acr:last-child{
    margin-bottom: 0 !important;
}
.dyn_acr::before{
    display: none;
}
.dyn_hdr {
    min-height: 0 !important;
    padding: 0 !important;
}
.dyn_hdr > div{
    margin: 0 !important;
}
.dyn_hdr h5 {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
    background: #FAFAFA;
    padding: 14px 16px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 700;
}
.dyn_acr.completed .dyn_hdr h5 {
    background-color: var(--secondry-color);
    color: #fff;
}
.dyn_hdr h5 svg {
    width: 20px;
    height: 20px;
}
.dyn_acr.completed .dyn_hdr h5 svg > *{
    fill: #fff;
}
.dyn_content {
    padding: 15px;
}