.sidebar,
.minSidebar{
    max-width: 260px;
    width: 100%;
    transition: all 0.3s ease-in-out 0.1s;
    background-color: var(--primary-color);
    height: 100vh;
    padding: 25px 0 0 0;
    position: sticky;
    top: 0;
    border-radius: 0 26px 26px 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgb(0 0 0 / 19%);
    z-index: 999999999;
}
.minSidebar{
    max-width: 65px;
}
.sidebar:after,
.minSidebar:after{
    content: "";
    position: absolute;
    width: 65px;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0 19px 19px 0px;
    background-color: var(--secondry-color);
    z-index: -1;
}
.navBrand{
    padding-left: 88px;
    transition: all 0.3s ease-in-out 0.1s;
}
.minSidebar .navBrand{
    padding-left: 10px;
    z-index: 99;
}
.navBrand img{
    max-width: 139px;
    object-fit: contain;
    margin-bottom: 45px;
    transition: all 0.3s ease-in-out 0.1s;
}
.sidebarNav{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 0 45px;
}
.sidebarNav::-webkit-scrollbar{
    display: none;
}
.sidebarNav li a {
    display: flex;
    color: #fff;
    border-left: 4px solid #0000;
    transition: all 0.3s ease-in-out 0.1s;
}

.sidebarNav li a.active,
.sidebarNav li a:hover{
    border-left-color: #fff;
}
.sidebarNav li a span:first-child {
    flex: 0 0 70px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.minSidebar .sidebarNav li a span:first-child{
    height: 42px;
}
.sidebarNav li a span:first-child img{
    width: 18px;
    object-fit: contain;
    height: 18px;
}

.navtext{
    padding: 12px 10px 12px 20px;
    flex-grow: 1;
    border-radius: 20px 0 0 20px;
    transition: all 0.3s ease-in-out 0.1s;
    display: inline-flex;
    align-items: center;
    height: 42px;
    white-space: nowrap;
    font-weight: 600;
}

.sidebarNav li a.active .navtext,
.sidebarNav li a:hover .navtext{
    background: #fff;
    color: #000;   
}
.minSidebar .navtext{
    display: none;
}
.minimizeBtn {
    background: var(--secondry-color);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.18);
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    left: calc(100% - 21px);
    top: 62px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0.1s;
}

.minimizeBtnCls{
    transform: rotate(180deg) translateY(-5px);
    background-color: var(--primary-color);
    width: 35px;
    height: 35px;
    padding: 14px;
}

div[role="tooltip"] {
    position: relative;
    z-index: 9999999999999;
}