.ntf_List {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ntf_Item {
    display: flex;
    gap: 24px;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 12px;
    transition: all 0.3s ease-in-out 0.1s;
}
.ntf_Item_text {
    flex-grow: 1;
}
.ntf_Item_text h5 {
    color: var(--primary-color);
    font-family: var(--font-family2);
    font-size: 16px;
    font-weight: 800;
    margin-bottom:5px;
}
.ntf_Item_text p {
    font-family: var(--font-family2);
    font-size: 15px;
    font-weight: 500;
    color: #1E1E1E;
}
.ntf_Item_text p a{
    color: var(--secondry-color);
}
.ntf_Item_Time {
    color: #797D8C;
    font-family: var(--font-family2);
    font-size: 12px;
    font-weight: 600;
}