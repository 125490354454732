.dash_wel.white_bx {
    padding: 0px 15px 20px 50px;
}
.dash_left {
    padding-top: 50px;
}
.dash_left h3 {
    color: #092C5B;
    font-size: 26px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 8px;
}
.dash_left p {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
}

.dash_left h6 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 30px;
}

.dash_rgt {
    margin-top: -40px;
}

/* Stat List */
.stat_List {
    display: flex;
    gap: 20px;
    margin: 40px 0;
}

.stat_card {
    display: flex;
    align-items: center;
    gap: 18px;
    flex: 0 0 calc(100% / 3 - 15px);
    border-radius: 16px;
    background: #FFF;
    padding: 22px;
}
.stat_icon {
    width: 50px;
    height: 50px;
}

.stat_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.stat_no {
    color: #000000b0;
    font-size: 18px;
    font-weight: 800;
    line-height: normal;
}
.stat_ctnt span {
    color: #030229c2;
    font-family: Nunito;
    font-size: 12px;
    font-weight: 400;
}

/* Calender */
.dash_calender_wpr {
    border-radius: 18px;
    background: #FFF;
    padding: 20px;
}

.dash_calender {
    border: 0 !important;
    position: relative;
    padding-top: 80px;
    width: 100% !important;
}

.dash_calender > * {
    font-family: var(--font-family2) !important;
}
.react-calendar__month-view__days{
    border-right: 1px solid #D5D4DF;
}
.react-calendar__month-view__days button {
    border: 1px solid #D5D4DF !important;
    border-top: 0 !important;
    border-right: 0 !important;
    height: 40px;
    font-weight: 600;
    color: #000;
}
.react-calendar__tile--active {
    background-color: var(--secondry-color) !important;
    color: #fff !important;
}
.react-calendar__month-view__weekdays__weekday {
    height: 40px;
    border-bottom: 1px solid #D5D4DF;
}
.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-size: 10px;
    font-weight: 800;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button, 
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}
.react-calendar__navigation__label {
    font-size: 12px !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
    background: #F2F3F7;
    color: #A8A8A8;
}

.dash_calender .react-calendar__navigation {
    position: absolute;
    top: 0;
    right: 0;
}

.react-calendar__navigation button {
    font-size: 30px;
    position: relative;
    z-index: 99;
}
.dash_time {
    margin-bottom: -50px;
    position: relative;
    z-index: 9;
}
.dash_time > div {
    color: #000;
    font-size: 10px;
    font-weight: 700;
}

.dash_time span {
    color: #000000b8;
    font-size: 12px;
    font-weight: 700;
}



/* ############### */
.dash_wel.dash_wel_bg {
    flex-direction: column;
    text-align: center;
    padding: 20px 40px 40px;
    position: sticky;
    top: 100px;
}

.dash_wel.dash_wel_bg .dash_rgt {
    margin-top: 0;
}