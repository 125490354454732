@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
/* Reset CSS*/
:root {
    --primary-color: #04103B;
    --secondry-color: #197BBD;
    --yellow-color:#E9A73E;
    --green-color:#377934;
    --pink-color:#E75A7C;
    --white-color: #ffffff;
    --black-color: #000000;
    --base-color: #F4F4F4;
    --gray-color:#a7a7a7;
    /* --font-family: 'Arial', sans-serif; */
    --font-family: 'Nunito', sans-serif;
    --font-family2 : 'Nunito', sans-serif;

}
*,body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-family);
}
.w-auto{
    width: unset !important;
}
.lh-0{
    line-height: 0;
}
.d-flex{
    display: flex;
}
.gap-10px{
    gap: 10px;
}
.justify-end{
    justify-content: flex-end;
}
.align-center{
    align-items: center;
}
.flex-wrap{
    flex-wrap: wrap;
}
.justify-space-between{
    justify-content: space-between !important;
}
body{
    background-color: var(--base-color);
}
.w-100{
    width: 100% !important;
}
.h-100{
    height: 100% !important;
}
.pe-none{
    pointer-events: none;
}
ul,li,ol{
   list-style: none; 
}
.prft_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.circle{
    border-radius: 50%;
}
a, a:hover{
    text-decoration: none;
}
.text {
    font-weight: 400;
    text-transform: capitalize;
    color: #000;
}
.cursor_pointer{
    cursor: pointer;
}
input:focus,
input:focus-visible,
input:focus-within,
textarea:focus,
textarea:focus-visible,
textarea:focus-within,
select:focus,
select:focus-visible,
select:focus-within{
    border: 0;
    outline: 0;
    box-shadow: none;
}
.cstm_Ctnr{
    padding: 0 50px;
}
.white_bx {
    display: flex;
    padding: 40px 50px;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
}
.min-vh{
    min-height: 100vh;
}
.sec-padding{
    padding: 40px 0;
}

.sec-padding-top{
    padding-top: 40px;
}

.sec-padding-bottom{
    padding-bottom: 40px;
}
.error_text{
    color: #ad1d1d;
    font-size: 12px;
    margin-top: 4px;
}
/* Margins */
.m-0{
    margin: 0px !important;
}
.mt-0{
    margin-top: 0px !important;
}
.mb-0{
    margin-bottom: 0px !important;
}

.mt-10{
    margin-top: 10px !important;
}
.mt-30{
    margin-top: 30px !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.mb-30{
    margin-bottom: 30px !important;
}
.mt-40{
    margin-top: 40px !important;
}

.me-10{
    margin-right: 10px !important;
}
.p-0{
    padding: 0px !important;
}
.p-30{
    padding: 30px !important;
}
.p-50{
    padding: 50px !important;
}
.transition{
    transition: all 0.3s ease-in-out 0.1s;
}
/* Typography */
.fz-14{
    font-size: 14px;
    line-height: 1.5em;
}
.fz-16 {
    font-size: 16px;
    line-height: 1.5em;
}
.fz-18 {
    font-size: 18px;
    line-height: 1.5em;
}
.fz-20 {
    font-size: 20px;
    line-height: 1.5em;
}
.fz-22 {
    font-size: 22px;
    line-height: 1.5em;
}
.fz-24 {
    font-size: 24px;
    line-height: 1.5em;
}

.primary-color{
    color: var(--primary-color) !important;
}

.secondry-color{
    color: var(--secondry-color) !important;
}

.pink-color{
    color: var(--pink-color) !important;
}

.green-color{
    color: var(--green-color) !important;
}
.text-dark label{
    color: #000 !important;
}
.left {
    text-align: left !important;
}
/* Buttons */
.btn_reset{
    border: 0;
    padding: 0;
    cursor: pointer;
    background-color: #0000;
    transition: all 0.3s ease-in-out 0.1s;
}
.download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: var(--pink-color);
    color: #fff;
    padding: 4px 15px 4px 16px;
    border-radius: 20px;
    font-size: 12px;
    gap: 14px;
    cursor: pointer;
}

.download_btn img {
    max-height: 18px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
}

.upload_btn img{
    max-width: 30px;
    max-height: 30px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
}
.theme_btn,
.blue_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: var(--pink-color);
    color: #fff;
    padding: 10px 26px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
}
.blue_btn{
    background-color: var(--secondry-color);
    padding: 12px 25px;
    border-radius: 35px;
}
.blue_btn:hover{
    background-color: var(--pink-color);
}
a.pink_btn, 
.pink_btn {
    display: inline-flex;
    border-radius: 24px;
    border: 1px solid var(--pink-color);
    background: var(--pink-color);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    padding: 9px 26px;
    color: #fff !important;
    font-size: 16px;
    transition: all 0.3s ease-in-out 0.1s;
}
.pink_btn:hover{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.pink_btn.sm {
    padding: 6px 24px;
    font-size: 0.8em;
}
.green_btn{
    background-color: var(--green-color);
    border-color: var(--green-color);
}
/* *********** */
/* Sidebar */
li a.active{
    border-left-color: #fff !important;
}
li a.active span:nth-child(2){
    background: #fff !important;
    color: #000 !important;   
}

/* Main Container */
/* Start Main container */
.main_container{
    display: flex;
}
.ctnt_wpr {
    flex: 1;
}
#logout_Btn {
    display: flex;
    align-items: center;
    gap: 8px;
}
#logout_Btn_Menu ul {
    padding: 0;
}
#logout_Btn_Menu .MuiPaper-elevation1{
    border-radius: 10px;
}

/* cutom Inputs */
.cstm_Input {
    flex-grow: 1;
    border-radius: 25px;
    border: 1px solid var(--secondry-color);
    padding: 12px 18px;
    font-size: 14px;
    color: #000;
}
.cstm_Input::placeholder{
    color: #949494;
}
.cstm_Input:focus{
    border: 1px solid var(--secondry-color);
    box-shadow: none;
    outline: 0;
}

.upload_input {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 15px;
    gap: 10px;
    border: 1px dashed #00000070;
    width: 100%;
    border-radius: 35px;
    font-size: 12px;
    color: #000;
    position: relative;
    z-index: 0;
    transition: all 0.3s ease-in-out 0.1s;
}

.upload_input input{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}
.upload_input .file_text{
    flex-grow: 1;
}
.file-select {
    color: var(--secondry-color);
    font-size: 9px;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid var(--secondry-color);
    display: inline-block;
    padding: 8px 20px;
    border-radius: 30px;
}

/* Privacy pages */
.privacy_layout {
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 50px;
    min-height: 80vh;
}

.privacy_heading {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 50px;
}
.privacy_heading_icon {
    height: 50px;
}

.privacy_heading_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.privacy_heading h4 {
    color: var(--primary-color);
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    position: relative;
}

.privacy_heading h4:before {
    content: "";
    height: 2px;
    width: 160px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    position: absolute;
    right: calc(100% + 20px);
}

.privacy_heading h4:after {
    content: "";
    height: 2px;
    width: 160px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    position: absolute;
    left: calc(100% + 20px);
}

.privacy_content p {
    color: rgba(0, 0, 0, 0.70);
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.54px;
}

/* Common Header */
.cmn_hdr{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.cmn_hdr h3 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}

.cmn_hdr a {
    color: #197BBD;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease 0.1s;
}
.cmn_hdr a:hover{
    transform: translateX(-10px);
}
/* Upcoming Task List */
.task_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.task_list_item h5 {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
    background: #FAFAFA;
    padding: 14px 16px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 700;
}
.task_list_item h5 svg {
    width: 22px;
    height: 22px;
}

/* Input Fields */
.input_field_Item:not(:last-child) {
    margin-bottom: 40px;
}

.input_label {
    text-transform: capitalize;
    color: var(--secondry-color);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
}

.input_field input {
    border-radius: 35px;
    border: 2px solid var(--secondry-color);
    background: #FDFDFD;
    padding: 14px 20px;
    width: 100%;
    font-size: 18px;
    line-height: 1.2em;
}

.input_field.icon {
    position: relative;
    display: flex;
}

.input_field.icon img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
}

.input_field.icon input {
    padding-right: 50px;
}
/* Login css */
.lgn_btn_wpr {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 50px;
}

.lgn_btn_wpr button {
    border-radius: 35px;
    border: 1px solid var(--pink-color);
    background: var(--pink-color);
    box-shadow: 0 4px 4px 0 #00000029;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 17px 24px;
    cursor: pointer;
}
.lgn_btn_wpr button:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);;
}
.lgn_btn_wpr a {
    color: var(--secondry-color);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.lgn_btn_wpr a:hover{
    color: var(--primary-color);
}