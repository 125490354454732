.tab-container {
    position: sticky;
    top: 100px;
}
.tab-titles {
    display: flex;
    align-items: center;
}
.tab-titles .tab-title {
    flex: 0 0 200px;
    max-width: 200px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background: #fff;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    padding: 16px 10px;
    cursor: pointer;
}
.tab-titles .tab-title:first-child{
    border-start-start-radius: 12px;
}
.tab-titles .tab-title:last-child{
    border-top-right-radius: 12px;
}
.tab-titles .tab_task{
    display: flex;
    height: 24px;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 38px;
    background: var(--pink-color);
    color: #fff;
}
/* .tab-titles .tab-title,
.tab-titles .tab-title img{
    transition: all 0.3s ease-in-out 0.1s;
} */
.tab-titles .tab-title.active {
    background: var(--secondry-color);
    border-radius: 12px 12px 0 0;
    color: #fff;
}
.tab_btn_full .tab-title {
    max-width: 100%;
    flex: 1;
}
.tab-titles .tab-title.active img{
    filter: invert(1);
}

.tab-titles .tab-title.active .tab_task{
    background-color: #fff;
    color: var(--secondry-color);
}
.tab-content{
    padding: 30px 0;
}